.layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 64px;
  box-shadow: 0px 1px 3px #646464;
  padding: 0 10px;

  .logo {
    height: 50px;
    max-height: 50px;
  }
}

.layout-header .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// = Mobile
@media (max-width: 576px) {
  .layout-header {

    .logo {
      max-height: 35px;
    }
  }

  .hidden-sm {
    display: none;
  }
}
