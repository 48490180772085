@import "../../styles/var";

.abob-theme {
  .select-language {
    [class^="Select-module_select__"] {
      width: 80px;
      margin-top: 0;

      [class^="Select-module_input__"] {
        padding: 5px 6px 5px 9px;

        [class^="Select-module_controls__"] {
          svg {
            padding-left: 6px;
          }
        }
      }
    }
  }
}

// = Mobile
@media (max-width: 420px) {
  .select-language div {
    margin-bottom: 5px;
  }
}
