$text-color: black;
$border-color: #93aab2;
$primary-color: #53948e;
$button-background-color: #ffeb3b;
$button-background-color-hover: #e8a704;
$danger-color: #f30c0c;

$body-background-color: #e9e9e959;

// = Selection
$selected-border-color: #eb964c;
$selected-background-color: #e3f1f1;
