.abob-theme {
  .CookieConsent {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    background-color: $primary-color;
    color: white;

    .cookie-consent-button {
      background-color: $button-background-color;
      color: white;
      border-radius: 20px;
      font-size: 1rem;
      padding: 5px 15px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: $button-background-color-hover;
      }
    }
  }
}
