// == HTML, BODY
html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: $body-background-color;
  color: $text-color;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

// == CONTAINER
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5px;
}

main.container {
  margin-bottom: 60px;
}

.form-content-header {
  margin-block-start: 0.33em;
  margin-block-end: 0.33em;
  margin-inline-start: 1em;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
