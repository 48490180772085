@import "../styles/var";

.form-question-input, .form-question-info {
  margin-bottom: 5px;
}

.form-actions-wrapper{
  padding-bottom: 15px;
}


// Mobile & Tablet
@media (max-width: 420px) {
  .form-actions-wrapper{
    //padding-bottom: 75px;
  }
  .form-actions-wrapper button {
    font-size: 0.7rem;
  }
}
