.form-input-group {
  display: flex;
  align-items: center;

  .form-input-unit {
    padding: 10px;
  }
}

.form-actions-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.left-actions {
  position: absolute;
  bottom: 10px;
  left: 10px;

  button + button {
    margin-left: 2rem;
  }
}

.top-left-actions {
  position: absolute;
  top: 75px;
  left: 10px;

  button + button {
    margin-left: 2rem;
  }
}

.right-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;

  button + button {
    margin-left: 2rem;
  }
}

.p-button:enabled:hover {
  background: $button-background-color-hover;
  color: $text-color;
  border-color: #e8a704;
}

.p-button:enabled:active {
  background: $button-background-color-hover;
  color: $text-color;
  border-color: #e8a704;
}

.p-button {
  background: $button-background-color;
  color: $text-color;
  border-color: #ffe23e;
  font-size: 25px;
  text-transform: uppercase;
}

label {
  font-size: 22px;
  color: $text-color;
}
