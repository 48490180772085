/*
 * ABOB UI: Component Styles
 */

@import url('https://fonts.cdnfonts.com/css/roboto');

.abob-theme {
  font-family: 'Roboto Medium';

  .p-component {
    font-family: 'Roboto Medium';
  }

  .p-card {
    color: $text-color;
  }

  // = Content
  [class^="Content-module_content__"] {
    margin-top: 0;
    padding: 0;
  }

  // = Question
  [class^="Question-module_question__"] {
    margin-bottom: 0;
    padding: 1.5rem;

    [class^="Question-module_content__"] {
      width: 100%;
    }

    [class^="Question-module_title__"] {
      margin-bottom: 10px;
    }
  }

  // = Panel
  [class^="Panel-module_panel__"] {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 1.5rem;
  }

  // = Radio
  [class^="Radio-module_container__"]
  [class^="Radio-module_checkmark__"]::after {
    top: 4px;
    left: 4px;
  }

  // = InputNumeric
  [class^="InputNumeric-module_numeric__"] {
    input[type="number"] {
      width: calc(136px - 50px);
    }
  }

  // = InputTextarea
  [class^="InputTextArea-module_main__"] {
    [class^="InputTextArea-module_textarea__"] {
      width: 100%;
      max-width: none;
    }
  }

  // = Dropdown
  [class^="Select-module_controls__"] > svg:last-of-type {
    box-sizing: initial;
  }

  // = Modal
  [class^="Modal-module_modal__"] {
    display: flex;
    flex-direction: column;
    padding: 20px;

    [class^="Modal-module_spacer__"] {
      padding: 0;
    }
  }

  // = Table 
  [class^="Table-module_table__"] {
    tbody {
      td {
        padding: 5px;
      }
    }
  }

  .datatable-action + .datatable-action {
    margin-left: 15px;
  }

  .text-danger {
    color: $danger-color
  }

  @media (max-width: 768px) {
    [class^="Modal-module_modal__"] {
      width: 100vw;
      min-height: 100vh;
      height: 100vh;

      [class^="Modal-module_body__"] {
        height: 100%;
        max-height: 100%;
      }
    }
  }
}
