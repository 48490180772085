
table {
  tbody {
    .form-summary-question {
      cursor: pointer;
    }
  }
}

tbody tr, th {
  word-break: break-word;
}

// Mobile & Tablet
@media (max-width: 420px) {
  .containerSummary {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .containerSummary div {/*
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;*/
  }
}
