.login-select-language{
  position: absolute;
  right: 12px;
  top: 12px;
}

.container {
  height: 100vh;

  .login-wrapper {
    .login-card {
      background-color: white;
      padding: 20px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
      max-width: 500px;
      max-height: 320px;

      .login-logo-wrapper {
        position: relative;
        text-align: center;
        margin-bottom: 20px;

        [class^="Select-module_select__"] {
          position: absolute;
          top: 0;
          right: 0;
          // width: 30px;
        }
      }

      .login-content {
        input {
          width: 100%;
          max-width: 100%;
          margin-top: 0;
        }
      }
    }
  }
}

// = Mobile
@media (max-width: 576px) {
  .login-wrapper {
    .login-card {
      .login-logo-wrapper {
        [class^="Select-module_select__"] {
          position: static;
          float: right;
        }
      }
    }
  }
}
